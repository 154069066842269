@import '../../styles/customMediaQueries.css';

.availabilityContainer {
  margin-top: 24px;
}

/* .availabilityContainer > div:nth-child(odd) {
  background-color: #cecece;
  border: 1px solid var(--colorGrey100);
}

.availabilityContainer > div:nth-child(even) {
  background-color: var(--colorWhite);
} */

.dayContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  /* background: #fff; */
  margin-top: 8px;
}

.availableContainer {
  background: #fff;
}

.notAvailableContainer {
  background: #ededed;
}

.dayHeader {
  font-weight: 500;
  color: #2c3e50;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.availabilityStatus {
  color: #2ecc71;
  font-size: 0.9rem;
  font-weight: bold;
}

.notAvailabilityStatus {
  color: #808080;
  font-size: 0.9rem;
}

.navigationButton {
  border: none;
  cursor: pointer;
}

/* .navigationButton:hover:not(:disabled) {
  background-color: #00c5d0;
  border-color: #00c5d0;
} */

.navigationButton:disabled {
  /* background-color: #e9ecef;
  border-color: #dee2e6; */
  color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}

.weekNavigation {
  margin: 24px 0;
}

.weekLabel {
  /* font-size: 1.25rem; */
  font-weight: 500;
  color: #2c3e50;
  margin-bottom: 16px;

  justify-content: space-between;
  align-items: center;

  @media (--viewportSmall) {
    display: flex;
  }
}

.dateRange {
  color: #00deea;
  font-weight: 500;
}

.deleteButton {
  padding: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
}

.editAvailabilityIcon {
  width: 16px;
}

.editSchedule {
  color: black;
  border: 1ox solid var(--marketplaceColor);
  cursor: pointer;
  background-color: var(--marketplaceColor);
  padding: 8px;
  font-weight: 500;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 10px;

  @media (--viewportSmall) {
    margin-top: 0px;
  }
}

.displayFlex {
  display: flex;
  gap: 8px;
  align-items: center;
}
